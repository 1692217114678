import { actions } from "../../../components/grid-views/standart-actions-without-view";
import { statusActions } from "../../../components/grid-views/status-actions";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'name',
    field: 'name',
    width: '45%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'status',
    field: 'status',
    width: '35%',
    formatFn: (value) => {
      if (value === 1) {
        return i18n.t('Active');
      }
      if (value === 0) {
        return i18n.t('Hidden');
      }
      return '';
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Subscribers',
    field: (value) => {
      return value && value.subscribers ? value.subscribers.length : 0;
    },
    width: '10%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...statusActions,
      ...actions,
    ]
  },
]
